import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Pagination,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomLoadingIndicator, Header } from "../../components";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DataGrid } from "@mui/x-data-grid";
import * as XLSX from "xlsx";

const TrackPageClicks = () => {
  const axiosPrivate = useAxiosPrivate();
  const [loadingIndicatorActive, setLoadingIndicatorActive] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  // const [userClicks, setUserClicks] = useState([]);
  // const [totalCount, setTotalCount] = useState(0);

  // const [totalUserClicks, setTotalUserClicks] = useState(0);
  // const [totalPages, setTotalPages] = useState(0);
  // const [page, setPage] = useState(1);
  // const [limit, setLimit] = useState(10);

  const [rowsData, setRowsData] = useState([]);
  const [columnsData, setColumnsData] = useState([
    {
      field: "name",
      headerName: "Campaign Name",
      flex: 1,
      checked: true,
      valueGetter: (value, params) => {
        if (!params?.campaign?.name.toString()) {
          return "-";
        }
        return params.campaign.name;
      },
    },
    {
      field: "messages_sent",
      headerName: "Sent",
      flex: 1,
      align: "center",
      headerAlign: "center",
      checked: true,
      valueGetter: (value, params) => {
        if (!params?.campaign?.stat_summary?.messages_sent.toString()) {
          return "-";
        }
        return params.campaign.stat_summary.messages_sent;
      },
    },
    {
      field: "total_success",
      headerName: "Success",
      flex: 1,
      align: "center",
      headerAlign: "center",
      checked: true,
      valueGetter: (value, params) => {
        if (!params?.campaign?.stat_summary?.total_success.toString()) {
          return "-";
        }
        return params.campaign.stat_summary.total_success;
      },
    },
    {
      field: "total_failure",
      headerName: "Failed",
      flex: 1,
      align: "center",
      headerAlign: "center",
      checked: true,
      valueGetter: (value, params) => {
        if (!params?.campaign?.stat_summary?.total_failure.toString()) {
          return "-";
        }
        return params.campaign.stat_summary.total_failure;
      },
    },
    {
      field: "opens_total",
      headerName: "Open",
      flex: 1,
      align: "center",
      headerAlign: "center",
      checked: true,
      valueGetter: (value, params) => {
        if (!params?.campaign?.stat_summary?.opens_total.toString()) {
          return "-";
        }
        return params.campaign.stat_summary.opens_total;
      },
    },
    {
      field: "opens_unique",
      headerName: "Unique Open",
      flex: 1,
      align: "center",
      headerAlign: "center",
      checked: true,
      valueGetter: (value, params) => {
        if (!params?.campaign?.stat_summary?.opens_unique.toString()) {
          return "-";
        }
        return params.campaign.stat_summary.opens_unique;
      },
    },
    {
      field: "clicks_total",
      headerName: "Email Click",
      flex: 1,
      align: "center",
      headerAlign: "center",
      checked: true,
      valueGetter: (value, params) => {
        if (!params?.campaign?.stat_summary?.clicks_total.toString()) {
          return "-";
        }
        return params.campaign.stat_summary.clicks_total;
      },
    },
    {
      field: "clicks_unique",
      headerName: "Email Unique Click",
      flex: 1,
      align: "center",
      headerAlign: "center",
      checked: true,
      valueGetter: (value, params) => {
        if (!params?.campaign?.stat_summary?.clicks_unique.toString()) {
          return "-";
        }
        return params.campaign.stat_summary.clicks_unique;
      },
    },
    {
      field: "pageClicks",
      headerName: "Page Click",
      flex: 1,
      align: "center",
      headerAlign: "center",
      checked: true,
      valueGetter: (value, params) => {
        if (!value.toString()) {
          return "-";
        }
        return value;
      },
    },
    {
      field: "pageUniqueClicks",
      headerName: "Page Unique Click",
      flex: 1,
      align: "center",
      headerAlign: "center",
      checked: true,
      valueGetter: (value, params) => {
        if (!value.toString()) {
          return "-";
        }
        return value;
      },
    },
  ]);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [rowCount, setRowCount] = useState(0);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  const [filters, setFilters] = useState({
    link: "",
    sendingDomain: "",
    receivingDomain: "",
    campaignName: "",
    dateFrom: null,
    dateTo: null,
  });

  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const [openExportDialog, setOpenExportDialog] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnackbar(false);
  };

  const handleExport = async () => {
    try {
      setLoadingIndicatorActive(true);
      const exportedColumnsData = columnsData
        .filter((col) => col.checked)
        .map((col) => col.field);
      const {
        data: { data: sheetData },
      } = await axiosPrivate.post(
        `/api/v1/production/reports/exportTrackPageClicks`,
        { filters },
        { headers: { "Content-Type": "application/json" } }
      );
      const columnMapping = {
        name: "Campaign Name",
        messages_sent: "Sent",
        total_success: "Success",
        total_failure: "Failed",
        opens_total: "Open",
        opens_unique: "Unique Open",
        clicks_total: "Email Click",
        clicks_unique: "Email Unique Click",
        pageClicks: "Page Click",
        pageUniqueClicks: "Page Unique Click",
      };
      const sheetDataFormatted = sheetData.map((sheet) => {
        return exportedColumnsData.reduce((acc, field) => {
          const value = field.includes("page")
            ? sheet[field]?.toString()
            : sheet.campaign?.stat_summary?.[field]?.toString() ||
              sheet.campaign?.[field]?.toString();
          if (value !== undefined) acc[columnMapping[field]] = value;
          return acc;
        }, {});
      });

      const fileName = `page_clicks_${Date.now()}`;
      const worksheet = XLSX.utils.json_to_sheet(sheetDataFormatted);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, fileName);
      XLSX.writeFile(workbook, `${fileName}.xlsx`);
      setSnackbarMessage("Successfully exported");
      setSnackbarSeverity("success");
      setShowSnackbar(true);
      handleCloseExportDialog();
    } catch (error) {
      setSnackbarMessage(error?.message || "Error occurred");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    } finally {
      setLoadingIndicatorActive(false);
    }
  };

  const handleCloseExportDialog = () => {
    setOpenExportDialog(false);
    // setSelectedRowsIds([]);
  };

  const handleColumnChange = (e) => {
    const cloneColumnsData = [...columnsData];
    const index = cloneColumnsData.findIndex(
      (col) => col.field === e.target.id
    );
    cloneColumnsData[index].checked = !cloneColumnsData[index].checked;
    setColumnsData(cloneColumnsData);
  };

  const getUserClicks = async (defaultFilters) => {
    setIsTableLoading(true);

    try {
      const response = await axiosPrivate.post(
        `/api/v1/production/reports/getUserClicks`,
        {
          page: paginationModel.page,
          limit: paginationModel.pageSize,
          filters: defaultFilters || filters,
        },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.data?.success) {
        // setUserClicks(response.data?.data);
        // setTotalUserClicks(response?.data?.total_records);
        // setTotalPages(Math.ceil(response?.data?.total_records / limit));

        setRowCount(response.data.total_records_row);
        // setTotalCount(response.data.total_records);
        setRowsData(response.data?.data);
      }
    } catch (error) {
      setSnackbarMessage(error?.response?.data?.message);
      setShowSnackbar(true);
      setSnackbarSeverity("error");
    } finally {
      setIsTableLoading(false);
    }
  };

  const handleFilterClick = async () => {
    getUserClicks();
  };

  const handleResetFiltersClick = async () => {
    setFilters({
      link: "",
      sendingDomain: "",
      receivingDomain: "",
      campaignName: "",
      dateFrom: null,
      dateTo: null,
    });
    getUserClicks({
      link: "",
      sendingDomain: "",
      receivingDomain: "",
      campaignName: "",
      dateFrom: null,
      dateTo: null,
    });
  };

  useEffect(() => {
    getUserClicks();
  }, [paginationModel]);

  return (
    <Box className="m-2 p-2 md:p-10 h-full bg-white rounded-3xl dark:text-gray-200 dark:bg-secondary-dark-bg">
      <Header category="Production" title="Track Page Clicks" />

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gridGap: "20px",
        }}
      >
        <TextField
          label="Campaign Name"
          size="small"
          value={filters.campaignName}
          onChange={(e) =>
            setFilters({ ...filters, campaignName: e.target.value })
          }
        />
        <TextField
          label="Sending Domain"
          size="small"
          value={filters.sendingDomain}
          onChange={(e) =>
            setFilters({ ...filters, sendingDomain: e.target.value })
          }
        />
        <TextField
          label="Search by Receiving Domain..."
          size="small"
          value={filters.receivingDomain}
          onChange={(e) =>
            setFilters({ ...filters, receivingDomain: e.target.value })
          }
        />
        <TextField
          label="Search by Link..."
          size="small"
          value={filters.link}
          onChange={(e) => setFilters({ ...filters, link: e.target.value })}
        />
        <DatePicker
          label="From Date"
          value={filters.dateFrom}
          onChange={(newValue) =>
            setFilters((prev) => ({
              ...prev,
              dateFrom: newValue,
            }))
          }
          renderInput={(params) => <TextField size="small" {...params} />}
        />
        <DatePicker
          label="To Date"
          value={filters.dateTo}
          onChange={(newValue) =>
            setFilters((prev) => ({
              ...prev,
              dateTo: newValue,
            }))
          }
          renderInput={(params) => <TextField size="small" {...params} />}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginBottom: "40px",
          marginTop: "20px",
        }}
      >
        <Button
          sx={{ marginRight: "20px" }}
          variant="contained"
          disabled={
            !filters.campaignName &&
            !filters.link &&
            !filters.receivingDomain &&
            !filters.sendingDomain &&
            (!filters.dateFrom || !filters.dateTo)
          }
          onClick={handleFilterClick}
        >
          Filter
        </Button>
        <Button
          variant="outlined"
          color="error"
          disabled={
            !filters.campaignName &&
            !filters.link &&
            !filters.receivingDomain &&
            !filters.sendingDomain &&
            (!filters.dateFrom || !filters.dateTo)
          }
          onClick={handleResetFiltersClick}
        >
          Reset Filters
        </Button>
      </Box>

      {rowsData.length ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            my: 4,
          }}
        >
          <Button
            variant="contained"
            color="success"
            onClick={() => setOpenExportDialog(true)}
          >
            Export
          </Button>
        </Box>
      ) : null}

      <Box sx={{ width: "100%" }}>
        <DataGrid
          disableColumnSorting
          disableColumnFilter
          disableRowSelectionOnClick
          autoHeight
          getRowId={(row) => row._id}
          rows={rowsData}
          columns={columnsData}
          rowCount={rowCount}
          loading={isTableLoading}
          pageSizeOptions={[10, 20, 50, 100]}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          // sortingMode="server"
          // onSortModelChange={handleSortModelChange}
          // checkboxSelection={rowsData.length ? true : false}
          // rowSelectionModel={selectedRowsIds}
          // onRowSelectionModelChange={(ids) => {
          //   setNumberOfLeads(ids.length);
          //   setSelectedRowsIds(ids);
          // }}
          // slotProps={{
          //   pagination: {
          //     ActionsComponent: TablePaginationActions,
          //   },
          // }}
        />
      </Box>

      {/* <Box sx={{ overflow: "auto" }}>
        {userClicks.length ? (
          <table id="groupDomainsTable" style={{ minWidth: "1200px" }}>
            <thead>
              <tr>
                <th>Campaign Name</th>
                <th>Sent</th>
                <th>Success</th>
                <th>Failed</th>
                <th>Open</th>
                <th>Unique Open</th>
                <th>Email Click</th>
                <th>Email Unique Click</th>
                <th>Page Click</th>
                <th>Page Unique Click</th>
              </tr>
            </thead>
            <tbody>
              {userClicks.map((item) => {
                return (
                  <tr key={item._id}>
                    <td title={item.campaign?.name}>{item.campaign?.name}</td>
                    <td title={item.campaign?.stat_summary?.messages_sent}>
                      {item.campaign?.stat_summary?.messages_sent}
                    </td>
                    <td title={item.campaign?.stat_summary?.total_success}>
                      {item.campaign?.stat_summary?.total_success}
                    </td>
                    <td title={item.campaign?.stat_summary?.total_failure}>
                      {item.campaign?.stat_summary?.total_failure}
                    </td>
                    <td title={item.campaign?.stat_summary?.opens_total}>
                      {item.campaign?.stat_summary?.opens_total}
                    </td>
                    <td title={item.campaign?.stat_summary?.opens_unique}>
                      {item.campaign?.stat_summary?.opens_unique}
                    </td>
                    <td title={item.campaign?.stat_summary?.clicks_total}>
                      {item.campaign?.stat_summary?.clicks_total}
                    </td>
                    <td title={item.campaign?.stat_summary?.clicks_unique}>
                      {item.campaign?.stat_summary?.clicks_unique}
                    </td>
                    <td title={item.pageClicks}>{item.pageClicks}</td>
                    <td title={item.pageUniqueClicks}>
                      {item.pageUniqueClicks}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : !loadingIndicatorActive ? (
          <Box sx={{ textAlign: "center" }}>No Results Found</Box>
        ) : null}
      </Box>

      {userClicks.length ? (
        <Box className="flex justify-between items-center my-4">
          <Select
            value={limit}
            onChange={(e) => {
              setPage(1);
              setLimit(e.target.value);
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
          {totalUserClicks > limit ? (
            <Pagination
              count={totalPages}
              page={page}
              onChange={(event, newPage) => setPage(newPage)}
            />
          ) : null}
        </Box>
      ) : null} */}

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <CustomLoadingIndicator isActive={loadingIndicatorActive} />

      <Dialog
        onClose={handleCloseExportDialog}
        open={openExportDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Choose which columns to export</DialogTitle>
        <DialogContent>
          <FormGroup
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr",
              alignItems: "center",
            }}
          >
            {columnsData.map((col) => {
              return (
                <FormControlLabel
                  key={col.field}
                  control={
                    <Checkbox
                      id={col.field}
                      checked={col.checked}
                      onChange={handleColumnChange}
                    />
                  }
                  label={col.headerName}
                />
              );
            })}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={handleCloseExportDialog}
          >
            Close
          </Button>
          <Button variant="outlined" color="success" onClick={handleExport}>
            Export
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TrackPageClicks;
