import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Pagination,
  Select,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomLoadingIndicator, Header } from "../../components";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ClearIcon from "@mui/icons-material/Clear";
import VisibilityIcon from "@mui/icons-material/Visibility";

const LinearProgressWithLabel = (props) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

const HealthScores = () => {
  const axiosPrivate = useAxiosPrivate();
  const [loadingIndicatorActive, setLoadingIndicatorActive] = useState(false);
  const [accounts, setAccounts] = useState([]);

  const [totalAccounts, setTotalAccounts] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchValue, setSearchValue] = useState("");

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  const [openDomainAverageDialog, setOpenDomainAverageDialog] = useState(false);

  const [selectedDomain, setSelectedDomain] = useState(null);
  const [averageHealthScore, setAverageHealthScore] = useState(0);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnackbar(false);
  };

  const getAccountsHealthScores = async (isEmpty) => {
    setLoadingIndicatorActive(true);

    try {
      const response = await axiosPrivate.get(
        `/api/v1/warmup/reports/getAccountsHealthScores?page=${page}&limit=${limit}&search=${
          isEmpty ? "" : searchValue
        }`,
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.data?.success) {
        setAccounts(response.data?.data);
        setTotalAccounts(response?.data?.total_accounts);
        setTotalPages(Math.ceil(response?.data?.total_accounts / limit));
      }
    } catch (error) {
      setSnackbarMessage(error?.response?.data?.message);
      setShowSnackbar(true);
      setSnackbarSeverity("error");
    } finally {
      setLoadingIndicatorActive(false);
    }
  };

  const handleViewDomainAverageClick = async (account) => {
    setLoadingIndicatorActive(true);

    try {
      const domain = account.split("@")[1];
      setSelectedDomain(domain);
      const response = await axiosPrivate.get(
        `/api/v1/warmup/reports/getAccountsHealthScores?page=1&limit=100&search=${domain}`,
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.data?.success) {
        setOpenDomainAverageDialog(true);
        const totalScore = response.data.data.reduce(
          (sum, { stat_warmup_score }) => sum + stat_warmup_score,
          0
        );
        const averageHealth = totalScore / response.data.data.length;

        const roundedAverageHealth = Math.round(averageHealth);

        if (!isNaN(roundedAverageHealth)) {
          setAverageHealthScore(roundedAverageHealth);
        }
      }
    } catch (error) {
      setSnackbarMessage(error?.response?.data?.message);
      setShowSnackbar(true);
      setSnackbarSeverity("error");
    } finally {
      setLoadingIndicatorActive(false);
    }
  };

  const handleCloseDomainAverageDialog = async () => {
    setOpenDomainAverageDialog(false);
  };

  useEffect(() => {
    getAccountsHealthScores();
  }, [page, limit]);

  return (
    <Box className="m-2 p-2 md:p-10 bg-white rounded-3xl dark:text-gray-200 dark:bg-secondary-dark-bg">
      <Header category="Warm Up" title="Accounts Health Scores" />
      <Stack direction={"row"} spacing={2} sx={{ my: 4 }}>
        <FormControl sx={{ width: "300px" }} variant="outlined">
          <InputLabel htmlFor="search">Search...</InputLabel>
          <OutlinedInput
            id="search"
            type={"text"}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setSearchValue("");
                    setPage(1);
                    getAccountsHealthScores(true);
                  }}
                  edge="end"
                >
                  {searchValue ? <ClearIcon /> : null}
                </IconButton>
              </InputAdornment>
            }
            label="Search..."
          />
        </FormControl>
        <Button
          variant="contained"
          color="success"
          onClick={() => {
            setPage(1);
            getAccountsHealthScores();
          }}
          disabled={!searchValue}
        >
          Search
        </Button>
      </Stack>
      {accounts.length ? (
        <table id="groupDomainsTable">
          <thead>
            <tr>
              <th style={{ textAlign: "left" }}>Account</th>
              <th>Health Score</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {accounts.map((item) => {
              let progressBackgroundColor, progressBackgroundColorFilled;

              if (item.stat_warmup_score < 84) {
                progressBackgroundColor = "#ff000050";
                progressBackgroundColorFilled = "#ff0000";
              } else if (item.stat_warmup_score <= 90) {
                progressBackgroundColor = "#ffa50050";
                progressBackgroundColorFilled = "#ffa500";
              } else if (item.stat_warmup_score <= 95) {
                progressBackgroundColor = "#ffff0050";
                progressBackgroundColorFilled = "#ffff00";
              } else if (item.stat_warmup_score <= 98) {
                progressBackgroundColor = "#90ee9050";
                progressBackgroundColorFilled = "#90ee90";
              } else if (item.stat_warmup_score > 98) {
                progressBackgroundColor = "#00800050";
                progressBackgroundColorFilled = "#008000";
              }
              return (
                <tr key={item.email}>
                  <td style={{ textAlign: "left" }}>{item.email}</td>
                  <td>
                    <LinearProgressWithLabel
                      value={item.stat_warmup_score}
                      sx={{
                        backgroundColor: progressBackgroundColor,
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: progressBackgroundColorFilled,
                        },
                      }}
                    />
                  </td>
                  <td>
                    <Tooltip title="View domain average">
                      <IconButton
                        onClick={() => handleViewDomainAverageClick(item.email)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : !loadingIndicatorActive ? (
        <Box sx={{ textAlign: "center" }}>No Accounts Found</Box>
      ) : null}
      {accounts.length ? (
        <Box className="flex justify-between items-center my-4">
          <Select
            value={limit}
            onChange={(e) => {
              setPage(1);
              setLimit(e.target.value);
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
          {totalAccounts > limit ? (
            <Pagination
              count={totalPages}
              page={page}
              onChange={(event, newPage) => setPage(newPage)}
            />
          ) : null}
        </Box>
      ) : null}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <CustomLoadingIndicator isActive={loadingIndicatorActive} />

      {selectedDomain ? (
        <Dialog
          open={openDomainAverageDialog}
          onClose={handleCloseDomainAverageDialog}
        >
          <DialogTitle>Health Score Average</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Health score average of
              <strong style={{ margin: "0 5px" }}>{selectedDomain}</strong>
              is
              <strong style={{ margin: "0 5px" }}>{averageHealthScore}%</strong>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={handleCloseDomainAverageDialog}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </Box>
  );
};

export default HealthScores;
